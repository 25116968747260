<template>
    <div class="stars" @click="router.push('/bewertung')">
        <span class="star" v-for="star in stars2">
            <font-awesome-icon icon="star" />
        </span>
        <p>
            {{ stars }} / 5
        </p>
    </div>
</template>
<script setup>
import { ref, onMounted } from '@vue/runtime-core'
import { useApi } from '@/lib/useApi'
import { useRouter } from 'vue-router'
    const router = useRouter()
    const stars = ref([])
    const stars2 = ref([])
    onMounted(async () => {
            stars.value = await useApi(`getBewertungenStars`)
            stars2.value = Math.round(stars.value)
    })
</script>
<style>
.star {
    color: gold;
}
.stars{
    margin: 10px 0 10px 0;
    text-align: left;
    cursor: pointer;
    display: flex;
}
.stars p{
    margin: 0;
    margin-left: 12px;
}
</style>