<template>
    <div class="flex">
        <div class="item-regular-price">
            <h3>
                <div v-if="isCouponValid">
                    <s>{{ regularPrice }}€</s>
                </div>
                <div v-else>{{ regularPrice }}€</div>
            </h3>
        </div>
        <div v-if="isCouponValid" class="item-discounted-price">
            <h3>{{ discountedPrice }}€</h3>
        </div>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
    setup() {
        const store = useStore()
        const isCouponValid = computed(() => store.state.shop.isCouponValid)
        const regularPrice = computed(() => store.state.shop.regularPrice)
        const discountedPrice = computed(() => store.state.shop.discountedPrice)

        return { isCouponValid, regularPrice, discountedPrice }
    },
})
</script>

<style scoped>
.flex {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
h3 {
    margin: 0;
}
.item-discounted-price {
    color: #b12705;
}
</style>
