<template>
    <div class="price">
        <div class="gift">
            <input
                id="gift"
                type="checkbox"
                v-model="gift"
                required
            />
            <label for="gift">{{ $t(`Components.Shop.Item.Gift`) }}</label>
        </div>
    </div>
</template>
<script>
import { defineComponent, onMounted, computed } from 'vue'
import ItemPrice from '@/components/Shop/ItemPrice'
import { debounce } from 'lodash'
import { useStore } from 'vuex'

export default defineComponent({
    components: {
        ItemPrice,
    },
    setup() {
        const store = useStore()
        const gift = computed({
            get() {
                return store.state.gift
            },
            set(value) {
                store.commit({
                    type: 'updateGift',
                    gift: value,
                })
            },
        })

        return {
            gift,
        }
    },
})
</script>

<style scoped>
.gift {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;;
    margin: 20px 0 0 0;
}
.gift input{
    margin: 0;
    margin-right: 14px;
}
</style>
