<template>
    <div class="buy-now-section">
        <div class="logo">
            <img src="@/assets/logo.png" alt="Schloss Burg-Sittensen Logo"/>
        </div>
        <div class="buy-text">
            <h2>{{ $t('Components.Shop.ProductSection.Titles[0]') }}</h2>
            <ItemPrice />

            <Stars />

            <p>{{ $t('Components.Shop.ProductSection.Texts[0]') }}</p>
            <p>{{ $t('Components.Shop.ProductSection.Texts[1]') }}</p>
            <div class="flex-box">
                <Item />
                <div id="paypal-button-container"></div>
            </div>
            <Gift />
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import router from '../../router'
import Item from './Item.vue'
import ItemPrice from './ItemPrice.vue'
import Stars from './Stars.vue'
import Gift from './Gift.vue'

export default defineComponent({
    components: {
        Item,
        ItemPrice,
        Stars,
        Gift
    },
    setup() {
        const store = useStore()
        const mounted = onMounted(() => {
            const PayPalScriptTag = document.createElement(`script`)
            PayPalScriptTag.setAttribute(
                `src`,
                // /* TestSandBox */`https://www.paypal.com/sdk/js?client-id=AZgAM4hBwy_M_e98JH5wYhFy-GG6kBQoVFQM5rMuPwFuW7Ba8NOS2ohI7SJKFUOvBp2Tuq8J_ky-gqP6&currency=EUR`
                /* Live System */`https://www.paypal.com/sdk/js?client-id=Aarja3vC-517vGeqFILEQg45FfuZp4rxfAdt5TZ7hcMyFXuZcRwcbC9zstDMzjUyKAs8d0UzaahL3jHo&currency=EUR`
            )
            PayPalScriptTag.addEventListener('load', setLoaded)
            document.head.appendChild(PayPalScriptTag)
        })
        const setLoaded = () => {
            paypal
                .Buttons({
                    style: {
                        size: 'responsive',
                        shape: 'pill',
                        label: 'pay',
                    },
                    fundingSource: paypal.FUNDING.PAYPAL,
                    createOrder: function () {
                        return fetch(
                            `https://${process.env.VUE_APP_URL}/backend/api/paypal/buy.php`,
                            // `https://${window.location.hostname}/backend/api/paypal/buy.php`,
                            {
                                method: 'post',
                                body: JSON.stringify({
                                    amount: store.state.shop.amount,
                                    item_id: store.state.shop.itemId,
                                    coupon: store.state.shop.coupon,
                                }),
                            }
                        )
                            .then(function (res) {
                                return res.json()
                            })
                            .then(function (data) {
                                return data.result.id // Use the key sent by your server's response, ex. 'id' or 'token'
                            })
                    },
                    onApprove: function (data) {
                        //console.log(`onApprove`, data)
                        const body = {
                            orderID: data['orderID'],
                            gift: store.state.shop.gift,
                        }
                        //console.log(body)
                        return fetch(
                            `https://${process.env.VUE_APP_URL}/backend/api/paypal/get.php`,
                            // `https://${window.location.hostname}/backend/api/paypal/get.php`,
                            {
                                method: 'POST',
                                body: JSON.stringify(body),
                            }
                        )
                            .then(function (res) {
                                return res.json()
                            })
                            .then(async function (details) {
                                //console.log(details)
                                //console.log(details.id)
                                /*console.log(
                                    `Transaction approved by ${details.payer.name.given_name}`
                                )*/
                                // Fetch AccessCode, wenn fehlgeschlagen, weiterleiten auf Hilfe Seite
                                let code = await fetch(`https://${process.env.VUE_APP_URL}/backend/api/shop/getAccessCode.php`, {method: 'POST', body: JSON.stringify({paypalId: details.id})}).then((res) => res.json())
                                store.commit({
                                    type: `updateAccessCode`,
                                    accessCode: code
                                })
                                store.commit({
                                    type: `updatePayPalId`,
                                    payPalId: details.id
                                })
                                store.commit({
                                    type: `updateEmail`,
                                    email: details.payer.email_address
                                })
                                localStorage.setItem('accessCode', code);
                                localStorage.setItem('payPalId', details.id);
                                // Wenn erfolgreich, in store speichern u. weiterleiten auf Login.vue
                                if(code) router.push('/login')
                                else router.push('/')
                                // AccessCode vorausfüllen
                            })
                            .catch((e) => console.error(e))
                    },
                })
                .render('#paypal-button-container')
        }
    },
})
</script>

<style scoped>
h2 {
    text-align: left;
    font-size: 18px;
    font-family: 'Frijole';
    color: var(--base-black);
}
p {
    font-size: 14px;
    text-align: left;
    color: var(--base-black);
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
.buy-now-section {
    display: flex;
    flex: 1;
    flex-direction: row;
    max-width: 1600px;
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}
.logo {
    flex-basis: 25%;
    margin: auto;
}
.logo img {
    width: 200px;
    max-width: 246px;
}
.buy-text {
    flex-basis: 75%;
}
.button {
    display: block;
    max-width: 300px;
    min-width: 200px;
    background-color: var(--base-color);
    color: var(--base-white);
    padding: 10px 30px;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 20px;
    border-radius: 30px;
    margin-right: 20px;
}
#paypal-button-container {
    display: block;
    width: 300px;
    min-width: 200px;
    margin-top: 20px;
    margin-left: 50px;
    height: 40px;
}
.flex-box {
    display: flex;
    flex: 1;
    flex-direction: row;
}
@media screen and (max-width: 1050px) {
    .buy-now-section {
        flex-direction: column;
    }
    .flex-box {
        flex-direction: row;
        justify-content: center;
    }
    #paypal-button-container {
        max-width: 300px;
        margin: auto;
        margin-top: 20px;
    }
    .button {
        min-width: 100px;
        margin: auto;
        margin-top: 20px;
    }
}
@media screen and (max-width: 800px) {
    .flex-box {
        flex-direction: column;
    }
    #paypal-button-container {
        max-width: 280px;
        margin: auto;
        margin-top: 20px;
    }
    .buy-now-section {
        padding-right: 15%;
        padding-left: 15%;
    }
}
</style>
