<template>
    <div class="home">
        <StartSlider />
        <InfoSection />
        <ProductSection />
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import InfoSection from '@/components/Shop/InfoSection.vue'
import StartSlider from '@/components/Shop/StartSlider.vue'
import ProductSection from '@/components/Shop/ProductSection.vue'

export default {
    components: {
        InfoSection,
        StartSlider,
        ProductSection,
    },
    name: 'Home',
    setup() {},
}
</script>
