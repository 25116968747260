<template>
    <div class="price">
        <div class="coupon">
            <input
                type="text"
                v-model="coupon"
                @keyup="checkIsCouponValid(), updateDiscountedPrice()"
                required
            />
            <label>{{ $t(`Components.Shop.Item.Label`) }}</label>
        </div>
    </div>
</template>
<script>
import { defineComponent, onMounted, computed } from 'vue'
import ItemPrice from '@/components/Shop/ItemPrice'
import { debounce } from 'lodash'
import { useStore } from 'vuex'

export default defineComponent({
    components: {
        ItemPrice,
    },
    setup() {
        const store = useStore()
        const coupon = computed({
            get() {
                return store.state.coupon
            },
            set(value) {
                // store.commit('updateCoupon', value)
                store.commit({
                    type: 'updateCoupon',
                    coupon: value,
                })
            },
        })

        onMounted(() => {
            updateRegularPrice()
            updateDiscountedPrice()
        })

        const checkIsCouponValid = debounce(
            () => store.dispatch({ type: 'checkIsCouponValid' }),
            333
        )

        const updateRegularPrice = debounce(
            () => store.dispatch({ type: 'updateRegularPrice' }),
            333
        )

        const updateDiscountedPrice = debounce(
            () => store.dispatch({ type: 'updateDiscountedPrice' }),
            333
        )

        return {
            coupon,
            checkIsCouponValid,
            updateDiscountedPrice,
        }
    },
})
</script>

<style scoped>
.coupon {
    position: relative;
    width: 70%;
    min-width: 200px;
    height: 40px;
}
.coupon input {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.562);
    outline: none;
    font-size: 17px;
}
.coupon label {
    position: absolute;
    bottom: 7px;
    left: 0px;
    width: 100%;
    text-align: left;
    pointer-events: none;
    font-size: 17px;
    transition: all 0.3s ease;
}
.coupon input:focus ~ label,
.coupon input:valid ~ label {
    transform: translateY(-30px);
    font-size: 15px;
    color: #1b1b1b;
}
.price {
    margin-top: 20px;
}
@media screen and (max-width: 1050px) {
    .coupon {
        width: 50%;
        min-width: 200px;
        margin: auto;
        margin-bottom: 20px;
    }
}
</style>
