<template>
    <div class="slider">
        <div class="bookmark"><img src="@/assets/mh-logo.png" alt="Mystery House Logo"/></div>
        <img src="@/assets/white_logo.png" alt="Mystery House Logo in Weiss"/>
        <h1>{{ $t('Components.Shop.StartSlider.Title') }}</h1>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
h1 {
    color: var(--base-white);
    font-family: 'Frijole';
    text-align: center;
    font-size: 2rem;
    font-weight: unset;
    text-align: center;
}
.slider {
    position: relative;
    background-image: url('~@/assets/burg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 400px;
}
.slider h1 {
    position: relative;
    top: 115px;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 50px;
}
.slider img {
    position: relative;
    width: 80px;
    padding: 10px;
    top: 110px;
}
.bookmark {
    position: absolute;
    margin-left: 20px;
    height: 50px;
    width: 40px;
}
.bookmark img {
    width: 160px;
    padding: 0px;
    top: 0px;
    border-radius: 0 0 12px 12px;
}
</style>
