<template>
    <div class="escape-block">
        <div class="about-escape">
            <h2>{{ $t('Components.Shop.InfoSection.Title') }}</h2>
            <p>{{ $t('Components.Shop.InfoSection.Text') }}</p>
        </div>
        <div class="features">
            <div class="feature-row">
                <div class="feature-item no-bold">
                    <h3>
                        {{ $t('Components.Shop.InfoSection.FeatureTitles[0]') }}
                    </h3>
                    <p>
                        {{ $t('Components.Shop.InfoSection.FeatureTexts[0]') }}
                    </p>
                </div>
                <div class="feature-item no-bold">
                    <h3>
                        {{ $t('Components.Shop.InfoSection.FeatureTitles[1]') }}
                    </h3>
                    <p>
                        {{ $t('Components.Shop.InfoSection.FeatureTexts[1]') }}
                    </p>
                </div>
                <div class="feature-item no-bold">
                    <h3>
                        {{ $t('Components.Shop.InfoSection.FeatureTitles[2]') }}
                    </h3>
                    <p>
                        {{ $t('Components.Shop.InfoSection.FeatureTexts[2]') }}
                    </p>
                </div>
            </div>
            <div class="feature-row">
                <div class="feature-item">
                    <h3>
                        {{ $t('Components.Shop.InfoSection.FeatureTitles[3]') }}
                    </h3>
                    <p>
                        {{ $t('Components.Shop.InfoSection.FeatureTexts[3]') }}
                    </p>
                </div>
                <div class="feature-item">
                    <h3>
                        {{ $t('Components.Shop.InfoSection.FeatureTitles[4]') }}
                    </h3>
                    <p>
                        {{ $t('Components.Shop.InfoSection.FeatureTexts[4]') }}
                    </p>
                </div>
                <div class="feature-item">
                    <h3>
                        {{ $t('Components.Shop.InfoSection.FeatureTitles[5]') }}
                    </h3>
                    <p>
                        {{ $t('Components.Shop.InfoSection.FeatureTexts[5]') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
p {
    color: white;
    text-align: left;
    font-size: 14px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
h2 {
    font-family: 'Frijole';
    font-size: 24px;
    color: white;
}
h3 {
    font-family: 'Frijole', cursive;
    font-size: 16px;
    color: white;
    text-align: left;
}
.escape-block {
    margin: 0;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: black;
}
.about-escape p {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}
.feature-row {
    max-width: 1600px;
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
}
.feature-item {
    flex-basis: 100%;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
}
@media screen and (max-width: 1000px) {
    .feature-row {
        flex-direction: column;
    }
    .escape-block {
        margin: 0;
        padding-right: 15%;
        padding-left: 15%;
    }
}
</style>
